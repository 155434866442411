export const sections = Object.freeze({
  letter: { deleted: false, loading: true, value: '' },
  treatment: { deleted: false, loading: true, value: [] },
  consent: { deleted: false, loading: true, value: '' },
});

export const statusFields = Object.freeze({
  CONSENT_DELETED: 'is_consent_deleted',
  TREATMENT_DELETED: 'is_treatment_deleted',
  LETTER_DELETED: 'is_letter_deleted',
});

export const states = Object.freeze({
  PENDING: 'pending',
});
