//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  colorMode: 'dark',
  data() {},

  watch: {
    isRecording(newVal) {
      if (newVal) {
        window.addEventListener('beforeunload', this.onBeforeUnload)
      } else {
        window.removeEventListener('beforeunload', this.onBeforeUnload)
      }
    },
  },

  mounted() {
    document.body.classList.add('light-theme')
    if (this.isRecording) {
      window.addEventListener('beforeunload', this.onBeforeUnload)
    }

    this.$root.confirmationPopup = this.$refs.confirmationPopup
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onBeforeUnload)
  },
  methods: {
    test() {},
    onBeforeUnload(event) {
      event.preventDefault()
      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true
      event.message = 'test'
    },
  },
}
