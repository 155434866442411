//
//
//
//
//
//
//

export default {
  middleware: 'BlockUserMiddleware',
  mounted() {
    //  this.$colorMode.preference = 'light'
    document.body.classList.add('light-old')

  },
}
