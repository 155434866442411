import { render, staticRenderFns } from "./light-blank.vue?vue&type=template&id=2dc55388&scoped=true&"
import script from "./light-blank.vue?vue&type=script&lang=js&"
export * from "./light-blank.vue?vue&type=script&lang=js&"
import style0 from "./light-blank.vue?vue&type=style&index=0&id=2dc55388&scoped=true&lang=css&"
import style1 from "./light-blank.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc55388",
  null
  
)

export default component.exports