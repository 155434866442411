import { render, staticRenderFns } from "./light-guide.vue?vue&type=template&id=52fc2d47&"
import script from "./light-guide.vue?vue&type=script&lang=js&"
export * from "./light-guide.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarLightGuide: require('/codebuild/output/src141926742/src/v3-UI/components/SidebarLightGuide.vue').default,ConfirmationLightPopup: require('/codebuild/output/src141926742/src/v3-UI/components/ConfirmationLightPopup.vue').default,MainAnnotationLoader: require('/codebuild/output/src141926742/src/v3-UI/components/Main/AnnotationLoader.vue').default})
