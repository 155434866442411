//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MixpanelMixins from '@/mixins/MixpanelMixins'
import IdleDetection from '@/modules/IdleDetection'
import SessionService from '@/services/modules/SessionService'
import AnalyticsInterface from '@/constants/mixpanel/AnalyticsInterface'
const { logConsultationCompleted, ConsultationModeList } = AnalyticsInterface

export default {
  mixins: [MixpanelMixins],
  data() {
    return {
      user: {
        id: this.$auth.user.id,
        name: this.$auth.user.first_name,
        profile_image: this.$auth.user.profile_image,
      },
      isSideMenuShow: false,
      uA: navigator.userAgent,
      vendor: navigator.vendor,
      safariBrowser: true,
      userSubscription: {},
      pro_user: this.$auth.user.proUser,
      student_user: this.$auth.user.studentUser,
      new_user: this.$auth.user.newPlan,
      dynamicTrackingRoute: '',
      loading: false,
    }
  },
  computed: {
    session() {
      return this.$store.getters['session/getCurrentSession']
    },
    openNavLinksInANewTabs() {
      return (
        this.$store.state.isVoiceNoteInProgress &&
        this.$route.fullPath.includes('voice-note')
      )
    },
    isInARecording() {
      return this.$store.getters['voice-note/getRecording']
    },
  },
  watch: {
    isInARecording(value) {
      if (this.session) {
        if (value) {
          IdleDetection.pauseDetection()
        } else {
          IdleDetection.resumeDetection()
        }
      }
    },
  },
  mounted() {
    if (
      navigator.vendor.match(/apple/i) &&
      !navigator.userAgent.match(/crios/i) &&
      !navigator.userAgent.match(/fxios/i) &&
      !navigator.userAgent.match(/Opera|OPT\//)
    ) {
      this.safariBrowser = false
    }
    this.$root.$on('CSYD:UpdateImage', this.onUpdateImage)
    this.$root.$on('CSYD:SideMenuToggle', this.toggleSideMenu)
    IdleDetection.init(this.$root)
    this.$root.$on('CSYD:idleDetected', this.idleDetected)
  },
  beforeDestroy() {
    this.$root.$off('CSYD:UpdateImage', this.onUpdateImage)
    this.$root.$off('CSYD:SideMenuToggle', this.toggleSideMenu)
    this.$root.$off('CSYD:idleDetected', this.idleDetected)
    IdleDetection.clearTimer()
  },
  methods: {
    async idleDetected() {
      if (this.session && !this.loading) {
        this.loading = true
        const sessionData = (await SessionService.stopSession(this.session.id))
          .data.data

        const startDate = new Date(sessionData.start)
        const endDate = new Date(sessionData.end)

        const durationSeconds = (endDate - startDate) / 1000
        logConsultationCompleted(
          ConsultationModeList.TimeoutCompleted,
          durationSeconds
        )
        this.$store.commit('session/removeSession')
        this.loading = false
      }
    },
    toggleSideMenu() {
      this.isSideMenuShow = !this.isSideMenuShow
    },
    closeSideMenu() {
      this.isSideMenuShow = false
      this.$root.$emit('CSYD:LandingPageReset')
    },
    fullscreenToggle() {
      if (
        !document.fullscreenElement && // alternative standard method
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          )
        }
      } else if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
      this.closeSideMenu()
    },
    async logout() {
      // eslint-disable-next-line no-undef
      if (chrome && chrome.runtime && chrome.runtime.sendMessage) {
        const extensionId = this.$config.extensionId
        if (extensionId) {
          // eslint-disable-next-line no-undef
          chrome.runtime.sendMessage(extensionId, {
            type: 'logged-out-chairsyde',
          })
        }
      }
      this.$echo.leave(`User.${this.$auth.user.id}`)

      this.$store.dispatch('voice-note/voiceNoteStoreClear')
      await this.$auth.logout()
      this.trackOnMixpanel('User logged out')
      this.$router.replace('/')
    },
    onUpdateImage($event) {
      this.user.profile_image = $event
    },
  },
}
