import Vue from 'vue'

import { confirmed, email, length, required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('email', {
  ...email,
  message: 'This field must be a valid email'
})

extend('confirmed', {
  ...confirmed,
  message: 'This field confirmation does not match'
})

extend('different_password', {
  params: ['target'],
  validate(value, { target }) {
    return value !== target
  },
  message: 'The new password is the same as your current password. Please enter a different password.'
})

extend('length', {
  ...length,
  message: 'This field must have 2 options'
})

extend('phone_number', {
  validate: value => value.match(/^[+0-9]{8,20}$[+0-9]*/),
  message: 'Enter a valid phone number '
})

extend('phone_number_without_country', {
  validate: value => value.match(/^(\+[\d]{1,5}|0)?[7-9]\d{9}$/),
  message: 'Enter a valid phone number.'
})

extend('youtube_url', {
  validate: value => value.match(/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/),
  message: 'Enter a valid url '
})

extend('password', value => {
  if (value.length < 8) return 'Password must be at least 8 characters'
  if (value.match(/[a-z]/) == null) return 'Password must contain at least one lowercase character'
  if (value.match(/[A-Z]/) == null) return 'Password must contain at least one uppercase character'
  if (value.match(/[0-9]/) == null) return 'Password must contain at least one number'
  if (value.match(/[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/) == null) return 'Password must contain at least one special character'
  return true
})

extend('currency', value => {
  const currencyRegex = /^(?!.*--)(?!.*\s)(?!-)\d+(\.\d+)?$/;
  const currencyPrefixes = ['$', '€', '₣','£','￡'];

  const currencySymbol = value.charAt(0);
  const numericValue = value.slice(1).trim();
  const decimal = numericValue.split('.')[1];

  // Check if value is empty
  if (value === '' || value === null) return 'This field is required.';

  // Validate currency symbol
  if (!currencyPrefixes.includes(currencySymbol)) return 'Invalid or unsupported currency symbol.';

  // Validate numeric part of the currency
  if (!currencyRegex.test(numericValue)) return 'Invalid currency format.';

  // Validate decimal places (if any)
  if (decimal && decimal.length > 2) return 'Enter a number with up to 2 decimal places.';

  // Validate maximum value
  if (parseFloat(numericValue) > 999999) return 'The price must not exceed 999,999.';

  return true;
})
