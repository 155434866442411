import moment from 'moment/src/moment'

export default {
  getDuration(session) {
    if (!session.end) {
      return 'Ongoing'
    }
    const duration = moment
      .utc(session.end)
      .diff(moment.utc(session.start), 'seconds')
    return this.formatDuration(duration, ',')
  },
  formatDuration(period, prefix = '') {
    const parts = []
    const duration = moment.duration(period, 'seconds')
    if (!duration || duration.toISOString() === 'P0D') return
    if (duration.years() >= 1) {
      const years = Math.floor(duration.years())
      parts.push(years + ' ' + (years > 1 ? 'years' : 'year'))
    }
    if (duration.months() >= 1) {
      const months = Math.floor(duration.months())
      parts.push(months + ' ' + (months > 1 ? 'months' : 'month'))
    }
    if (duration.days() >= 1) {
      const days = Math.floor(duration.days())
      parts.push(days + ' ' + (days > 1 ? 'days' : 'day'))
    }
    if (duration.hours() >= 1) {
      const hours = Math.floor(duration.hours())
      parts.push(hours + ' ' + (hours > 1 ? 'hours' : 'hour'))
    }
    if (duration.minutes() >= 1) {
      const minutes = Math.floor(duration.minutes())
      parts.push(minutes + ' ' + (minutes > 1 ? 'minutes' : 'minute'))
    }
    if (duration.seconds() >= 1) {
      const seconds = Math.floor(duration.seconds())
      parts.push(seconds + ' ' + (seconds > 1 ? 'seconds' : 'second'))
    }
    return parts.join(prefix + ' ')
  },
  formatDurationShort(period, prefix = '') {
    const parts = []
    const duration = moment.duration(period, 'seconds')
    if (!duration || duration.toISOString() === 'P0D') return
    if (duration.years() >= 1) {
      const years = Math.floor(duration.years())
      parts.push(years + ' y')
    }
    if (duration.months() >= 1) {
      const months = Math.floor(duration.months())
      parts.push(months + ' mon')
    }
    if (duration.days() >= 1) {
      const days = Math.floor(duration.days())
      parts.push(days + ' d')
    }
    if (duration.hours() >= 1) {
      const hours = Math.floor(duration.hours())
      parts.push(hours + ' hr')
    }
    if (duration.minutes() >= 1) {
      const minutes = Math.floor(duration.minutes())
      parts.push(minutes + ' min')
    }
    if (duration.seconds() >= 1) {
      const seconds = Math.floor(duration.seconds())
      parts.push(seconds + ' sec')
    }
    return parts.join(prefix + ' ')
  },
  getSimpleTypeFromBackendType(type) {
    const classMap = {
      'Modules\\Core\\Entities\\Condition': 'condition',
      'Modules\\Core\\Entities\\SubCondition': 'sub-condition',
      'Modules\\Core\\Entities\\Treatment': 'treatment',
      'Modules\\Core\\Entities\\SubTreatment': 'sub-treatment',
      'Modules\\Core\\Entities\\Risk': 'risk'
    }
    return classMap[type]
  },
  getBackendTypeFromSimpleType(type) {
    const classMap = {
      'condition': 'Modules\\Core\\Entities\\Condition',
      'sub-condition': 'Modules\\Core\\Entities\\SubCondition',
      'treatment': 'Modules\\Core\\Entities\\Treatment',
      'sub-treatment': 'Modules\\Core\\Entities\\SubTreatment',
      'risk': 'Modules\\Core\\Entities\\Risk'
    }
    return classMap[type]
  },
  b64toBlob(dataurl, filename) {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  },
  getMimeType(file, fallback = null) {
    const byteArray = new Uint8Array(file).subarray(0, 4)
    let header = ''
    for (let i = 0; i < byteArray.length; i++) {
      header += byteArray[i].toString(16)
    }
    switch (header) {
      case '89504e47':
        return 'image/png'
      case '47494638':
        return 'image/gif'
      case 'ffd8ffe0':
      case 'ffd8ffe1':
      case 'ffd8ffe2':
      case 'ffd8ffe3':
      case 'ffd8ffe8':
        return 'image/jpeg'
      default:
        return fallback
    }
  },
  openPopupCenter({ url, title, w, h }) {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

    const systemZoom = width / window.screen.availWidth
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, `_blank`,
      `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
    )
    if (window.focus) newWindow.focus()
    return newWindow
  },

  getVideoCover(file, seekTo = 0.0) {
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement('video')
      videoPlayer.setAttribute('src', URL.createObjectURL(file))
      videoPlayer.load()
      videoPlayer.addEventListener('error', (ex) => {
        reject(new Error('error when loading video file'), ex)
      })
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject(new Error('video is too short.'))
          return
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo
        }, 200)
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener('seeked', () => {
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement('canvas')
          canvas.width = videoPlayer.videoWidth
          canvas.height = videoPlayer.videoHeight
          // draw the video frame to canvas
          const ctx = canvas.getContext('2d')
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
          // return the canvas image as a blob

          resolve(canvas.toDataURL(
            'image/png'
          ))
        })
      })
    })
  },
  convertGivenTreatmentsToHavePrice (existingTreatments = []) {
    return existingTreatments.map((x) => {
      let price = ""
      if(x.currency){
        price += x.currency
      }

      if(x.price){
        price += x.price
      }
      x.formattedPrice = price
      return x
    })
  }
}
