export const lightModes = [
  '/admin',
  '/tracking',
  '/patient',
  '/login',
  '/email',
  '/change-password',
  '/cancel',
  '/sign-up',
  '/signUpNew',
  '/unavailable',
  '/welcome',
  '/stepper',
  '/soe',
  'appointments',
  '/terms',
  '/voice-note',
  '/guide',
]
