import {
  lightModes
} from "@/constants/color"

export default function ({
  app,
  $axios
}) {
  $axios.get('/sanctum/csrf-cookie')

  app.router.afterEach((to, from) => {
    if (to.path === '/') {
      document.body.classList.add('light-theme')
      document.body.classList.add('light-old')

    } else if (
      lightModes.reduce((acc, x) => acc || to.path.includes(x), false)
    ) {
      document.body.classList.add('light-theme')
      document.body.classList.add('light-old')

    } else {
      document.body.classList.remove('light-theme')
      document.body.classList.remove('light-old')

    }

    // if (to.path === '/tracking') {
    //   document.body.classList.add('dark-convert')
    // } else {
    //   document.body.classList.remove('dark-convert')
    // }
  })
}
