import { axiosIns } from '@/services/axios'
import apiResource from '@/services/service_utils'

const baseUrl = '/api/voice-notes'

export default {
  ...apiResource(baseUrl),
  chat: (payload) => axiosIns().post(`${baseUrl}/chat`, payload),
  audit: (payload) => axiosIns().post(`${baseUrl}/audit`, payload),
  transcribe: (payload) => axiosIns().post(`${baseUrl}/transcribe`, payload),
  token: () => axiosIns().get(`${baseUrl}/token`),
  save: (payload) => axiosIns().post(`${baseUrl}/save`, payload),
  updateVoiceNote: (id, payload) => axiosIns().put(`${baseUrl}/${id}/update`, payload),

  getAllActiveSnippetsOfAuthUser: (payload) => axiosIns().get(`${baseUrl}/get-all-active-snippets`),
  snippets: apiResource(`${baseUrl}/snippets`),
  templates: apiResource(`${baseUrl}/templates`),

  createBundle: (payload) => axiosIns().post(`${baseUrl}/create-bundle`, payload),
  sendBundleViaEmail: (payload) => axiosIns().post(`${baseUrl}/bundle/send-email`, payload),
  getBundleFromNote: (noteId) => axiosIns().get(`${baseUrl}/bundle/${noteId}/get-note-bundles`),
  getBundle: (bundleId) => axiosIns().get(`${baseUrl}/bundle/${bundleId}`),
  toggleBundleStatus: (bundleId, column) => axiosIns().put(`${baseUrl}/bundle/${bundleId}/toggle-status/${column}`),
  updateBundle: (bundleId, section, payload) => axiosIns().put(`${baseUrl}/bundle/${bundleId}/update-bundle/${section}`, payload),
  updateBundleTreatment: (bundleId, payload) => axiosIns().put(`${baseUrl}/bundle/${bundleId}/update-treatments`, payload),
  getDetailsAboutGivenTreatmentsAndSubTreatments: (payload) => axiosIns().post(`${baseUrl}/bundle/get-details`, {items:payload}),
  getBundlePdf: (bundleId) => axiosIns().get(`${baseUrl}/bundle/${bundleId}/get-email-summary-pdf`),
}
