import Vue from 'vue'
import VuePlyr from 'vue-plyr/dist/vue-plyr.ssr.js'
import 'plyr/dist/plyr.css'

export const controls = `
<div class='plyr__controls pb-4 px-5-tablet'>
    <div class='columns m-0 mr-5 p-0 flex-grow progress-bar' style='flex-grow:1'>
        <div class='column m-0 p-0'>
            <div class='plyr__progress'>
                <input data-plyr='seek' type='range' min='0' max='100' step='0.01' value='0' aria-label='Seek'>
                <progress class='plyr__progress__buffer' min='0' max='100' value='0'>% buffered</progress>
                <span role='tooltip' class='plyr__tooltip'>00:00</span>
            </div>
            <div class='columns is-mobile m-0 p-0'>
                <div class='column p-0 is-narrow'>
                    <div style='font-size: 10px;' class='plyr__time plyr__time--current' aria-label='Current time'>00:00</div>
                </div>
                <div class='column p-0'> </div>
                <div class='column p-0 mr-2 is-narrow'>
                    <div style='font-size: 10px;' class='plyr__time plyr__time--speed speed' aria-label='Speed'>00:00</div>
                </div>
                <div class='column p-0 is-narrow'>
                    <div style='font-size: 10px;' class='plyr__time plyr__time--duration' aria-label='Duration'>00:00</div>
                </div>
            </div>
        </div>
    </div>


    <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control prv-control' onClick="window.plyerEmit(this,'prv')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i class='mdi mdi-skip-previous mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Previous media</span>
    </div>
    <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control' data-plyr='rewind'>
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i class='mdi mdi-rewind mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Rewind {seektime} secs</span>
    </div>
    <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control' aria-label='Play, {title}' data-plyr='play'>
        <span class='icon has-text-white icon--pressed' style='position:unset'><i class='mdi mdi-pause mdi-18px'></i></span>
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i class='mdi mdi-play mdi-18px'></i></span>

        <span class='label--pressed plyr__tooltip' role='tooltip'>Pause</span>
        <span class='label--not-pressed plyr__tooltip' role='tooltip'>Play</span>
    </div>
    <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control' data-plyr='fast-forward'>
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i class='mdi mdi-fast-forward mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Forward {seektime} secs</span>
    </div>
    <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control' onClick="window.plyerEmit(this,'slower')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i class='mdi mdi-speedometer-slow mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Slow Down</span>
    </div>
    <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control' onClick="window.plyerEmit(this,'faster')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i class='mdi mdi-speedometer mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Speed Up</span>
    </div>
    <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control next-control' onClick="window.plyerEmit(this,'next')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i class='mdi mdi-skip-next mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Next Media</span>
    </div>
      <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control' onClick="window.plyerEmit(this,'fullScreen')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'> <i class='fas fa-lg fa-expand-alt mdi-18px'></i>
        <span class='plyr__tooltip' role='tooltip'>Full Screen</span>
    </div>


</div>
<div class='middleButtons is-flex is-justify-content-space-between'>
    <div class='plyr__control' data-plyr='rewind' onClick="window.plyerEmit(this,'back10')">
        <i class='mdi mdi-rewind-10 mdi-48px'></i>
    </div>
    <button type='button' class='plyr__control play-icon' onClick="window.plyerEmit(this,'playPause')">
        <i id='icon' class='mdi mdi-play mdi-48px'></i>
    </button>
    <div class='plyr__control' data-plyr='fast-forward' onClick="window.plyerEmit(this,'forward10')">
        <i class='mdi mdi-fast-forward-10 mdi-48px'></i>
    </div>
</div>
`

//    <div class="mb-4 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control" data-plyr="settings">
//     <span class="icon has-text-white icon--not-pressed" style="position:unset"><i class="mdi mdi-cog mdi-18px"></i></span>
// </div>
export const controlsPatient = `
<div class='plyr__controls pb-4 px-5-tablet'>
  <div class='columns m-0 mr-5 p-0 flex-grow progress-bar' style='flex-grow:1'>
    <div class='column m-0 p-0'>
      <div class='plyr__progress'>
        <input data-plyr='seek' type='range' min='0' max='100' step='0.01' value='0' aria-label='Seek'>
        <progress class='plyr__progress__buffer' min='0' max='100' value='0'>% buffered</progress>
        <span role='tooltip' class='plyr__tooltip'>00:00</span>
      </div>
    </div>
  </div>

  <div class="player-wrapper">
    <div class="player-left-side">
      <div class='thumb plyr__control' aria-label='Play, {title}' data-plyr='play'>
        <span class='icon has-text-white icon--pressed' style='position:unset'><i
            class='mdi mdi-pause mdi-18px'></i></span>
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><svg width="14" height="18"
            viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.8139 8.37596C13.2592 8.67283 13.2592 9.32717 12.8139 9.62404L1.41603 17.2226C0.917609 17.5549 0.25 17.1976 0.25 16.5986L0.250001 1.40139C0.250001 0.802365 0.91761 0.445072 1.41603 0.77735L12.8139 8.37596Z"
              fill="white" /></svg></span>
        <span class='label--pressed plyr__tooltip' role='tooltip'>Pause</span>
        <span class='label--not-pressed plyr__tooltip' role='tooltip'>Play</span>
      </div>
      <div class='thumb plyr__control' data-plyr='fast-forward'>
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><svg width="9" height="14"
            viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.87439 13.2524L7.12695 6.99988L0.874391 0.747314" stroke="white" stroke-width="1.5"
              stroke-linecap="round" /></svg>
          <span class='plyr__tooltip' role='tooltip'>Forward {seektime} secs</span>
      </div>
      <div class='thumb plyr__control' onClick="window.plyerEmit(this,'slower')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'>
          <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.873657 13.2524L7.12622 6.99988L0.873658 0.747314" stroke="white" stroke-width="1.5"
              stroke-linecap="round" />
          </svg>
          <span class='plyr__tooltip' role='tooltip'>Slow Down</span>
      </div>
      <div class='thumb plyr__control' onClick="window.plyerEmit(this,'faster')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><svg
            xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
            <path d="M1.49913 13L7.76367 7L1.49913 1" stroke="white" stroke-width="1.5" stroke-linecap="round" />
            <path d="M10.2355 13L16.5 7L10.2355 0.999999" stroke="white" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          <span class='plyr__tooltip' role='tooltip'>Speed Up</span>
      </div>
      <div class='thumb plyr__control next-control' onClick="window.plyerEmit(this,'next')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><svg
            xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none" >
            <path d="M1.49939 14.252L7.75195 7.99939L1.49939 1.74683" stroke="white" stroke-width="1.5"
              stroke-linecap="round" />
            <path d="M10.5 0.875V15.125" stroke="white" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          <span class='plyr__tooltip' role='tooltip'>Next Media</span>
      </div>
      <div class='columns player-time m-0 p-0'>
        <div class='column p-0 is-narrow'>
          <div style='font-size: 10px;' class='plyr__time plyr__time--current' aria-label='Current time'>
            00:00</div>
        </div>
        <div class='column p-0 mr-2 ml-2'>/</div>
        <div class='column p-0 is-narrow'>
          <div style='font-size: 10px;' class='plyr__time plyr__time--speed speed' aria-label='Speed'>
            00:00</div>
        </div>
        <div class='column p-0 is-narrow'>
          <div style='font-size: 10px;' class='plyr__time plyr__time--duration' aria-label='Duration'>
            00:00</div>
        </div>
      </div>
    </div>
    <div class="player-right-side">
      <div class='thumb plyr__control volume'>
        <span id='plyr_volume_button' class='icon has-text-white icon--not-pressed' style='position:unset'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.571 8.99987H2.25C2.05109 8.99987 1.86032 9.07889 1.71967 9.21954C1.57902 9.36019 1.5 9.55096 1.5 9.74987V14.2499C1.5 14.4488 1.57902 14.6395 1.71967 14.7802C1.86032 14.9209 2.05109 14.9999 2.25 14.9999H6.571C7.12356 16.4147 8.05581 17.6496 9.26512 18.5687C10.4744 19.4878 11.9139 20.0553 13.425 20.2089C13.5292 20.2192 13.6345 20.2076 13.734 20.1748C13.8335 20.142 13.925 20.0888 14.0027 20.0186C14.0804 19.9483 14.1425 19.8626 14.1851 19.7669C14.2277 19.6712 14.2498 19.5676 14.25 19.4629V4.53687C14.25 4.43207 14.228 4.32845 14.1854 4.23267C14.1429 4.1369 14.0808 4.0511 14.003 3.98081C13.9253 3.91053 13.8337 3.85731 13.7341 3.82459C13.6346 3.79188 13.5293 3.78039 13.425 3.79087C11.9139 3.94442 10.4744 4.51198 9.26512 5.43104C8.05581 6.3501 7.12356 7.58503 6.571 8.99987Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M21.4648 15.5492C22.142 14.489 22.5018 13.2572 22.5018 11.9992C22.5018 10.7412 22.142 9.50943 21.4648 8.44922"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M18.5625 14.2418C19.0674 13.6197 19.3429 12.843 19.3429 12.0418C19.3429 11.2406 19.0674 10.4639 18.5625 9.8418"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <span class='plyr__tooltip' role='tooltip'>Volume</span>
        </span>
        <input id="volumeSlider" class="plyr__volume" type="range" min="0" max="1" step="0.01" value="1"
          oninput="window.plyerEmit(this, 'updateVolume')">
      </div>
      <div class='thumb plyr__control' onClick="window.plyerEmit(this,'fullScreen')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><svg width="24" height="24"
            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_37_18351)">
              <path d="M23.25 0.748047L14.25 9.74805" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M9.75 20.998V14.248H3" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M14.25 2.99805V9.74805H21" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M9.75 14.248L0.75 23.248" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M0.75 0.748047L9.75 9.74805" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M14.25 20.998V14.248H21" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M9.75 2.99805V9.74805H3" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M14.25 14.248L23.25 23.248" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_37_18351">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class='plyr__tooltip' role='tooltip'>Full Screen</span>
      </div>
      <div class='thumb plyr__control prv-control' onClick="window.plyerEmit(this,'prv')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i
            class='mdi mdi-skip-previous mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Previous media</span>
      </div>
      <div class='thumb plyr__control' data-plyr='rewind'>
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i
            class='mdi mdi-rewind mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Rewind {seektime} secs</span>
      </div>
      <div class='thumb plyr__control slow-down' onClick="window.plyerEmit(this,'slower')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i
            class='mdi mdi-speedometer-slow mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Slow Down</span>
      </div>
    </div>
    <div class='columns is-mobile m-0 p-0'>
      <div class='column p-0 is-narrow'>
        <div style='font-size: 10px;' class='plyr__time plyr__time--current' aria-label='Current time'>00:00
        </div>
      </div>
      <div class='column p-0'> </div>
      <div class='column p-0 mr-2 is-narrow'>
        <div style='font-size: 10px;' class='plyr__time plyr__time--speed speed' aria-label='Speed'>00:00
        </div>
      </div>
      <div class='column p-0 is-narrow'>
        <div style='font-size: 10px;' class='plyr__time plyr__time--duration' aria-label='Duration'>00:00
        </div>
      </div>
    </div>
  </div>
</div>
<div class='middleButtons is-flex is-justify-content-space-between'>
  <div class='plyr__control' data-plyr='rewind' onClick="window.plyerEmit(this,'back10')">
    <i class='mdi mdi-rewind-10 mdi-48px'></i>
  </div>
  <button type='button' class='plyr__control play-icon' onClick="window.plyerEmit(this,'playPause')">
    <svg width="41" height="53" viewBox="0 0 41 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.0878 24.5898C40.4732 25.5134 40.4732 27.5491 39.0878 28.4727L3.62763 52.1128C2.07701 53.1466 1.47597e-06 52.035 1.55744e-06 50.1714L3.62412e-06 2.89112C3.70558e-06 1.0275 2.07701 -0.0840809 3.62764 0.949671L39.0878 24.5898Z"
        fill="white" />
    </svg>
  </button>
  <div class='plyr__control' data-plyr='fast-forward' onClick="window.plyerEmit(this,'forward10')">
    <i class='mdi mdi-fast-forward-10 mdi-48px'></i>
  </div>
</div>

`
export const mobileControls = `
<div
  class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control play-pause-btn plyr_mobile_button'
  aria-label='Play, {title}' data-plyr='play'>
  <span class='icon has-text-white icon--pressed mobile-icon-push' style='position:unset'><svg width="29" height="48"
      viewBox="0 0 29 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3.00391V45.0039" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M25.7461 3V45" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
    </svg></span>
  <span class='icon has-text-white icon--not-pressed mobile-icon-play' style='position:unset'><svg width="41"
      height="53" viewBox="0 0 41 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.0878 24.5898C40.4732 25.5134 40.4732 27.5491 39.0878 28.4727L3.62763 52.1128C2.07701 53.1466 1.47597e-06 52.035 1.55744e-06 50.1714L3.62412e-06 2.89112C3.70558e-06 1.0275 2.07701 -0.0840809 3.62764 0.949671L39.0878 24.5898Z"
        fill="white" /></svg>
  </span>

  <span class='label--pressed plyr__tooltip mobile-icon-push' role='tooltip'>Pause</span>
  <span class='label--not-pressed plyr__tooltip  mobile-icon-play' role='tooltip'>Play</span>
</div>
<div class='plyr__controls pb-4 px-5-tablet'>
  <div class='columns m-0 mr-5 p-0 flex-grow progress-bar' style='flex-grow:1'>
    <div class='column m-0 p-0'>
      <div class='plyr__progress'>
        <input data-plyr='seek' type='range' min='0' max='100' step='0.01' value='0' aria-label='Seek'>
        <progress class='plyr__progress__buffer' min='0' max='100' value='0'>% buffered</progress>
        <span role='tooltip' class='plyr__tooltip'>00:00</span>
      </div>
    </div>
  </div>
</div>
<div class="custom-mobile-controls">

  <div class='thumb plyr__control volume'>
    <span id='plyr_volume_button' class='icon has-text-white icon--not-pressed' style='position:unset'>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.571 8.99987H2.25C2.05109 8.99987 1.86032 9.07889 1.71967 9.21954C1.57902 9.36019 1.5 9.55096 1.5 9.74987V14.2499C1.5 14.4488 1.57902 14.6395 1.71967 14.7802C1.86032 14.9209 2.05109 14.9999 2.25 14.9999H6.571C7.12356 16.4147 8.05581 17.6496 9.26512 18.5687C10.4744 19.4878 11.9139 20.0553 13.425 20.2089C13.5292 20.2192 13.6345 20.2076 13.734 20.1748C13.8335 20.142 13.925 20.0888 14.0027 20.0186C14.0804 19.9483 14.1425 19.8626 14.1851 19.7669C14.2277 19.6712 14.2498 19.5676 14.25 19.4629V4.53687C14.25 4.43207 14.228 4.32845 14.1854 4.23267C14.1429 4.1369 14.0808 4.0511 14.003 3.98081C13.9253 3.91053 13.8337 3.85731 13.7341 3.82459C13.6346 3.79188 13.5293 3.78039 13.425 3.79087C11.9139 3.94442 10.4744 4.51198 9.26512 5.43104C8.05581 6.3501 7.12356 7.58503 6.571 8.99987Z"
          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M21.4648 15.5492C22.142 14.489 22.5018 13.2572 22.5018 11.9992C22.5018 10.7412 22.142 9.50943 21.4648 8.44922"
          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M18.5625 14.2418C19.0674 13.6197 19.3429 12.843 19.3429 12.0418C19.3429 11.2406 19.0674 10.4639 18.5625 9.8418"
          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <span class='plyr__tooltip' role='tooltip'>Volume</span>
    </span>
  </div>
</div>
`
export const playerDevice = window.innerWidth > 991 ? 'desktop' : 'mobile';


Vue.use(VuePlyr, {
  plyr: {
    // ratio: '4:3',
    // iconUrl: require('../src/assets/video-play-icon.png'),
    // controls: ['play-large', 'progress'],
    // controls: [],
    storage: {
      enabled: false,
      key: 'plyr'
    },
    hideControls: true,
    fullscreen: {
      enabled: false
    },
  },
})
