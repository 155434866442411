import { cloneDeep } from 'lodash'
import VoiceNoteService from '@/services/modules/VoiceNoteService'
import { sections,states } from '@/constants/voiceNoteEnums'

export const state = () => ({
  showBundle: false,
  showBundleActionButtons : false,
  noteBundles: [],
  currentBundle: null,
  currentBundleSections: cloneDeep(sections),
  currentBundleId: null,
  showCreateBundle: true,
  showSendBundle: false,
  isBundleCreating: false,
  showUpdateConsentNotification: false,
  showUpdatesAvailableForNoteNotification: false,
  showUpdateBundle:false,
  updatesAvailableForConsent: false,
  updatesAvailableForLetter: false,
  selectedSectionName: '',
  key:0
})

export const getters = {
  getKey (state) {
    return state.key
  },
  hasAtLeastOneNonDeletedSection (state){
    return Object.values(state.currentBundleSections).some(item => item.deleted === false);
  },
  isCurrentBundleIsInPendingState (state) {
    return state.currentBundle?.state === 'pending'
  },
  getUpdatesAvailableForLetter(state) {
    const currentBundle = state.currentBundle
    return currentBundle?.updates_available_for_letter && !currentBundle?.is_letter_deleted
  },
  getShowBundle(state) {
    return state.showBundle
  },
  getShowUpdateBundle(state) {
    return state.showUpdateBundle
  },
  getShowUpdatesAvailableForNoteNotification(state) {
    return state.showUpdatesAvailableForNoteNotification
  },
  getUpdatesAvailableForConsent(state) {
    return state.updatesAvailableForConsent && !state.currentBundleSections.consent.deleted
  },
  getShowBundleActionButtons(state) {
    return state.showBundleActionButtons
  },
  getNoteBundles(state) {
    return state.noteBundles
  },
  getCurrentBundle(state) {
    return state.currentBundle
  },
  getCurrentBundleId(state) {
    return state.currentBundleId
  },
  getCurrentBundleSections(state) {
    return state.currentBundleSections
  },
  getShowCreateBundle(state) {
    return state.showCreateBundle
  },
  getShowSendBundle(state) {
    return state.showSendBundle
  },
  getIsBundleCreating(state) {
    return state.isBundleCreating
  },
  getSelectedSectionName(state) {
    return state.selectedSectionName
  },
}
export const mutations = {
  SET_KEY (state){
    state.key++
  },
  RESET_CURRENT_BUNDLE_SECTIONS (state){
    state.currentBundleSections = cloneDeep(sections)
  },
  SET_GIVEN_SECTION_KEY_VALUE(state, { section, key, value }) {
    state.currentBundleSections[section][key] = value
  },
  SET_UPDATES_AVAILABLE_FOR_LETTER(state, value) {
    state.currentBundle.updates_available_for_letter = value
  },
  SET_SHOW_ACTION_BUNDLES(state, value) {
    state.showBundleActionButtons = value
  },
  SET_UPDATES_AVAILABLE_FOR_CONSENT(state, value) {
    state.updatesAvailableForConsent = value
  },
  SET_SHOW_BUNDLE(state, value) {
    state.showBundle = value
  },
  SET_CHANGE_LOADING_OF_ALL_SECTIONS(state, value) {
    state.currentBundleSections.letter.loading= value
    state.currentBundleSections.treatment.loading= value
    state.currentBundleSections.consent.loading= value
  },
  SET_NOTE_BUNDLES(state, value) {
    state.noteBundles = value
  },
  SET_CURRENT_BUNDLE(state, value) {
    state.currentBundle = value
  },
  SET_CURRENT_BUNDLE_SECTIONS(state, value) {
    state.currentBundleSections = value
  },
  SET_CURRENT_BUNDLE_ID(state, value) {
    state.currentBundleId = value
  },
  SET_SHOW_CREATE_BUNDLE(state, value) {
    state.showCreateBundle = value
  },
  SET_SHOW_SEND_BUNDLE(state, value) {
    state.showSendBundle = value
  },
  SET_IS_BUNDLE_CREATING(state, value) {
    state.isBundleCreating = value
  },
  SET_SHOW_UPDATES_AVAILABLE_FOR_NOTE(state, value) {
    state.showUpdatesAvailableForNoteNotification = value
  },
  SET_SHOW_UPDATE_BUNDLE(state, value) {
    state.showUpdateBundle = value
  },
  SET_REST_STATE(state) {
    state.showBundle = false
    state.noteBundles = []
    state.currentBundle = null
    state.currentBundleSections = cloneDeep(sections)
    state.currentBundleId = null
    state.showCreateBundle = false
    state.showSendBundle = false
    state.isBundleCreating = false
    state.showUpdateConsentNotification = false
    state.showBundleActionButtons = false
    state.updatesAvailableForConsent = false
    state.showUpdatesAvailableForNoteNotification = false
    state.showUpdateBundle = false
    state.selectedSectionName = ''
    state.updatesAvailableForLetter = false
    state.key = 0
  },
  SET_SELECTED_SECTION_NAME(state, selectedSectionName) {
    state.selectedSectionName = selectedSectionName
  },
  UPDATE_NOTE_BUNDLES(state, value) {
    const index = state.noteBundles.findIndex(bundle => bundle.id === value.id);
    if (index !== -1) {
      state.noteBundles.splice(index, 1, value);  // Replace the existing bundle
    } else {
      state.noteBundles.push(value);
    }
  },
}
export const actions = {
  async getBundleFromNote({ commit }, noteId) {
    try {
      const { data } = await VoiceNoteService.getBundleFromNote(noteId)
      const bundles = data || []
      commit('SET_NOTE_BUNDLES', bundles)
    } catch (error) {}
  },
  async getBundle({ commit }, bundleId) {
    try {
      const { data } = await VoiceNoteService.getBundle(bundleId)
      commit('SET_CURRENT_BUNDLE', data.data)
    } catch (error) {}
  },
  setCurrentBundleSections({ commit }, sections) {
    commit('SET_CURRENT_BUNDLE_SECTIONS', sections)
  },
  addBundleSection({ commit, state }, data) {
    const sections = JSON.parse(JSON.stringify(state.currentBundleSections))
    const currentBundle = JSON.parse(JSON.stringify(state.currentBundle))
    if (sections[data.section]) {
      sections[data.section].deleted = false
    }
    currentBundle[data.statusField] = false
    commit('SET_CURRENT_BUNDLE', currentBundle)
    commit('SET_CURRENT_BUNDLE_SECTIONS', sections)
  },
  removeBundleSection({ commit, state }, data) {
    const sections = JSON.parse(JSON.stringify(state.currentBundleSections))
    const currentBundle = JSON.parse(JSON.stringify(state.currentBundle))
    if (sections[data.section]) {
      sections[data.section].deleted = true
    }
    currentBundle[data.statusField] = true
    commit('SET_CURRENT_BUNDLE', currentBundle)
    commit('SET_CURRENT_BUNDLE_SECTIONS', sections)
  },
  addTreatmentsToBundleSection({ commit, state }, treatments) {
    const sections = JSON.parse(JSON.stringify(state.currentBundleSections))
    if (sections.treatment) {
      sections.treatment.value.push(...treatments)
    }
    commit('SET_CURRENT_BUNDLE_SECTIONS', sections)
  },
  removeTreatmentToBundleSection({ commit, state }, index) {
    const sections = JSON.parse(JSON.stringify(state.currentBundleSections))
    if (sections.treatment) {
      sections.treatment.value.splice(index, 1)
    }
    commit('SET_CURRENT_BUNDLE_SECTIONS', sections)
  },
  updateBundleSection({ commit, state }, data) {
    const sections = JSON.parse(JSON.stringify(state.currentBundleSections))
    if (sections[data.section]) {
      sections[data.section].value = data.value
    }
    commit('SET_CURRENT_BUNDLE_SECTIONS', sections)
  },
  updateTreatmentToBundleSection({ commit, state }, treatment) {
    const sections = JSON.parse(JSON.stringify(state.currentBundleSections))
    if (sections.treatment) {
      const existingTreatment = sections.treatment.value.find(
        (x) => x.id === treatment.id
      )
      if (existingTreatment) {
        Object.assign(existingTreatment, treatment) // Update the existing treatment
      }
    }
    commit('SET_CURRENT_BUNDLE_SECTIONS', sections)
  },
  setSelectedSectionName({ commit }, selectedSectionName) {
    commit('SET_SELECTED_SECTION_NAME', selectedSectionName)
  },
  async updateSectionWithSelectedBundle({ commit }, bundle) {
    commit('SET_SELECTED_SECTION_NAME', '')
    commit('SET_CURRENT_BUNDLE_ID', bundle.id)
    commit('SET_CURRENT_BUNDLE', bundle)
    await commit('SET_GIVEN_SECTION_KEY_VALUE',{ section: 'letter', key: 'deleted', value: bundle.is_letter_deleted})
    await commit( 'SET_GIVEN_SECTION_KEY_VALUE', { section: 'letter', key: 'value', value: bundle.letter, })

    await commit('SET_GIVEN_SECTION_KEY_VALUE',{ section: 'consent', key: 'value', value: bundle.consent})
    await commit('SET_GIVEN_SECTION_KEY_VALUE',{ section: 'consent', key: 'deleted', value: bundle.is_consent_deleted})
    await commit('SET_GIVEN_SECTION_KEY_VALUE',
      {
        section: 'treatment',
        key: 'value',
        value: (bundle.json_attribute?.treatments ?? []).map((x) => {
          let price = ""
          if (x.currency) {
            price += x.currency
          }

          if (x.price) {
            price += x.price
          }
          x.formattedPrice = price
          return x
        }),
      }
    )
    await commit('SET_GIVEN_SECTION_KEY_VALUE',{ section: 'treatment', key: 'deleted', value: bundle.is_treatment_deleted})
    await commit('SET_SHOW_BUNDLE', true)
    const isADraftBundle = bundle.state === states.PENDING
    await commit('SET_SHOW_SEND_BUNDLE', isADraftBundle)
    await commit('SET_SHOW_ACTION_BUNDLES', true)
    await commit('SET_SHOW_CREATE_BUNDLE', !isADraftBundle)
    await commit('SET_CHANGE_LOADING_OF_ALL_SECTIONS', false)
    setTimeout(() => commit('SET_KEY'),100)

  },
  resetState({commit}) {
    commit('SET_REST_STATE')
  }
}
